<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <!-- <span class="main-title mt-2 mb-2">User Management</span> -->
    <PrimeCard>
      <template #content>
        <DataTable
          :value="services"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="userid"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <div class="flex">
              <!-- <div class="col-6"></div> -->
              <div class="project">Project Management / </div>
              <div class="process"> Project Process</div>
              <PrimeButton
                label="Add New Task"
                class="add-new-task-button"
                @click="gotoProjectProcess(this.$route.params.id)"
              ></PrimeButton>
              <!-- </div> -->
            </div>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column
            field="taskname"
            header="Project Process Task"
            sortable
          ></Column>
          <Column field="status" header="Status" sortable
            ><template #body="data">
              <div class="status-cell">
                <i
                  class="pi pi-circle-fill mr-2 mt-2"
                  :class="{
                    'status-icon-green': data.data.status === 'completed',
                    'status-icon-blue': data.data.status === 'inprogress',
                    'status-icon-white': data.data.status === 'pending',
                  }"
                ></i>
                {{ data.data.status.toUpperCase() }}
              </div>
            </template></Column
          >

          <Column field="action" header="Action">
            <template #body="data">
              <div class="flex" style="gap: 8px">
                <PrimeButton
                  label="Edit"
                  class="edit-button"
                  @click="gotoUserService(this.$route.params.id, data.data.id)"
                />
                <PrimeButton
                  label="Delete"
                  class="delete-button ml-2"
                  @click="showDeletePopup(this.$route.params.id, data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <ConfirmPopup
            :show-popup="showPopup"
             :header="header"
            :message="message"
            @confirmYes="deleteService"
            @confirmNo="showPopup = false"
          ></ConfirmPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
// import MemberFactory from "@/factories/member";
export default {
  data() {
    return {
      visibleDDMenu: false,
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      userIdForDelete: "",
      showPopup: false,
      services: [],
      projectid: "",
    };
  },
  mounted() {
    this.getServiceList();
  },
  methods: {
    gotoProjectProcess(userId) {
      console.log(userId, "userId");
      this.$router.push({
        name: "ProjectProcessCreate",
        params: { projectid: userId },
      });
    },
    gotoUserService(projectId, userId) {
      this.$router.push({
        name: "ProjectProcessEdit",
        params: { projectid: projectId, id: userId },
      });
    },
    showDeletePopup(userId) {
       this.header = "Delete Project Process";
      this.message =
        "Are you sure you want to delete the this task? <br/> Once you confirm this action, it will be deleted <br/> immediately. You can't undo this action.";
      this.userIdForDelete = userId;
      this.showPopup = true;
    },
    getServiceList() {
      this.services = [];
      // let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/task/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.$route.params.id}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.services = response.data.data;
          console.log(response.data.data, 33);
        }
      });
    },
    deleteService() {
      APIService.delete(`/task/${this.userIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getServiceList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getServiceList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "phone") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getServiceList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
.status-icon-green {
  color: #00b42a;
  font-size: 0.7rem;
}
.status-icon-blue {
  color: #165dff;
  font-size: 0.7rem;
}
.status-icon-white {
  color: #c9cdd4 !important;
  font-size: 0.7rem;
}
.project {
  //styleName: 14/CN-Regular;
  font-family: PingFang Sc;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4e5969 !important;
  white-space: break-spaces !important; 
}
.process {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: BOLD !important;
  color: #1d2129 !important;
}
</style>
