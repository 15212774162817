export default class Project {
    constructor(data = {}) {
      return {
        id: data.id || "",
        projectid: data.projectid || "",
        title: data.title || "",
        memberid: data.memberid || "",
        username: data.username || "",
        status: data.status || "",
        images: data.images || "",
      };
    }
  }