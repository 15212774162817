<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.userid"
                sectionClass="field"
                id="userid"
                label="User ID"
                class="disable-text-field"
                placeholder="User Id"
                disabled
                v-if="isEditMode"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                sectionClass="field"
                id="username"
                label="User Name"
                placeholder="User Name"
                maxlength="50"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                sectionClass="field"
                id="email"
                label="Email"
                placeholder="Email"
                maxlength="50"
              />
            </div>
          </div>
  
          <div class="mt-4">
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              @click="updateUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,

} from "@vuelidate/validators";
// import getPassword from "@/libs/getPassword";
// import { isValidPassword } from "@/services/validator.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isEditMode: true,
      vmodel: {
        username: "",
        email: "",
        // phone: "",
        password: "",
        confirmpassword: "",
      },
    };
  },
  mounted() {
    this.getUserById();
  },
  validations() {
    return {
      vmodel: {
        username: { required, max: maxLength(50) },
        // phone: { required, numeric, min: minLength(8), max: maxLength(8) },
        email: { required, email, max: maxLength(50) },
      },
    };
  },
  methods: {
    //     async updateUser() {
    //   this.v$.vmodel.$touch();
    //   if (this.v$.vmodel.$invalid) {
    //     return;
    //   }

    //   let payload = {
    //     username: this.vmodel.username,
    //     email: this.vmodel.email,
    //     phone: this.vmodel.phone,
    //     // Include other fields as needed for the update.
    //   };

    //   try {
    //     const response = await APIService.put(
    //       "/membermanagement/update",
    //       payload,
    //       this.$route.params.id
    //     );

    //     if (response && response.data && response.data.success) {
    //       this.$toast.add({
    //         severity: "success",
    //         summary: "Success",
    //         detail: response.data.message,
    //         life: 3000,
    //       });
    //       this.$router.push({ name: "MemberManagement" });
    //     } else {
    //       this.$toast.add({
    //         severity: "error",
    //         summary: "Error",
    //         detail: response.data.message,
    //         life: 3000,
    //       });
    //     }
    //   } catch (error) {
    //     console.error("Error updating user:", error);
    //     this.$toast.add({
    //       severity: "error",
    //       summary: "Error",
    //       detail: "An error occurred while updating the user.",
    //       life: 3000,
    //     });
    //   }
    // },
    async updateUser() {
      this.v$.vmodel.$touch();
      if (this.v$.vmodel.$invalid) {
        return;
      }
      let payload = {
        username: this.vmodel.username,
        email: this.vmodel.email,
        phone: this.vmodel.phone,
        // Include other fields as needed for the update.
      };

      APIService.put("/membermanagement/update", payload, this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    async getUserById() {
      APIService.getById("/membermanagement/getid", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phone = response.data.data.phone;
            // let password = decodeURIComponent(response.data.data.password);
            // let decryptedPassword = getPassword.getPassword(password);
            // this.vmodel.password = decryptedPassword;
            // this.vmodel.confirmpassword = decryptedPassword;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
.p-disabled,
.p-component:disabled {
  opacity: 0 !important;
  color: #1d2129 !important;
}
</style>
