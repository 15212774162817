export default class Showcase {
    constructor(data = {}) {
      return {
        id: data.id || "",
        showcaseid: data.showcaseid || "",
        title: data.title || "",
        content: data.content || "",
        images: data.images || "",
      };
    }
  }