import Project from "@/models/project";

export default class ProjectFactory {
  static createFromJson(json) {
    return new Project(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(ProjectFactory.createFromJson(item));
    });

    return jsonData;
  }
}
