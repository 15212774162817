<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4 flex flex-column">
          
              <InputTextRequire
                v-model="vmodel.title"
                :validation="v$.vmodel.title"
                sectionClass="field"
                id="title"
                label="Service Title"
                placeholder="Title"
                maxlength="50"
                :required="true"
              />
            </div>
          </div>
          <!-- <label for="photo" class="form-label">News Content</label> -->
          <div class="grid">
            <div class="col-4">
              <TextAreaRequire
                v-model="vmodel.content"
                :validation="v$.vmodel.content"
                sectionClass="field"
                class="w-full areaheight"
                label="Service Content"
                id="content"
                 :required="true"
                placeholder="Please enter..."
                maxlength="50"
              />
            </div>
          </div>

          <div class="mt-4">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createUser"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      image: "",
      categoryList: [],
      active: false,
      documentname: "",
      documenturl: "",
      imagename: "",
      imageid: "",
      vmodel: {
        title: "",
        content: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit Service";
      this.isEditMode = true;
      this.getProductById();
    }

  },
  validations() {
    return {
      vmodel: {
        title: { required, max: maxLength(50) },
        content: {},
      },
    };
  },
  methods: {
    async deletefile() {
      this.vmodel.image = "";
      this.image = "";
    },
    async downloadfile() {
      let image = new Image();
      image.src = this.vmodel.image;
      var newTab = window.open();
      newTab.document.body.innerHTML = image.outerHTML;
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
      let payload = {
        // newsid: this.vmodel.newsid,
       
        title: this.vmodel.title,
        content: this.vmodel.content,
      };
      this.submitted = true;
      if (this.$route.params.id) {
        APIService.put("/service", payload, this.$route.params.id)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "ServiceManagement" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        APIService.post("/service", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "ServiceManagement" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
      }
    },
    async getProductById() {
      this.image = "";
      APIService.getById("/service", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.title = response.data.data.title;
            this.vmodel.content = response.data.data.content;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ServiceManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.areaheight) {
  .p-inputtext {
    width: inherit !important;
    min-height: 58px !important;
  }
}
.img-style {
  height: 22.5px;
  width: 40px;
}
.photo-section {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 16px;
  gap: 24px;

  // width: 415px;
  width: 100% !important;
  height: 160px;

  background: #f2f3f5;

  border: 1px dashed #e5e6eb;
  border-radius: 2px;
}
.catalogue-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  isolation: isolate;

  width: 100%;
  height: 36px;
  background: #f7f8fa;
  border-radius: 2px;
}
.set-margin {
  margin-left: 13px;
  width: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.remove-icon {
  margin-right: -20px;
}
.upload-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;

  width: 153px;
  height: 32px;

  /* Primary/1 */

  background: #901a1c;
  border-radius: 2px;

  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* 特殊/White */

  color: #ffffff;
}

.upload-img {
  border-radius: 1px;
  align-self: center;
  align-content: end;
  text-align: center;
  margin: inherit;
  margin-left: 134px;
}
.upload-text {
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 383px;
  height: 22px;
  color: #1d2129;
  text-align: center;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
.validation-point {
  color: #f53f3f;
}
// .upload-img {
//   border-radius: 50px;
// }
// .upload-img-box {
//   position: relative;
//   height: 80px;
//   width: 80px;
//   border-radius: 50px;
// }
// .remove-button {
//   background: transparent !important;
//   border: none !important;
// }
// .overlay {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 0px;
//   gap: 8px;
//   isolation: isolate;
//   width: 80px;

//   position: absolute;
//   height: 80px;

//   background: rgba(29, 33, 41, 0.6) !important;
//   border-radius: 50px;
//   top: 0;
// }
</style>
