// import DateFormat from "@/libs/DateFormat";

export default class Service {
  constructor(data = {}) {
    return {
      id: data.id || "",
      serviceid: data.serviceid || "",
      title: data.title || "",
      content: data.content || "",
    };
  }
}
