import Showcase from "@/models/showcase";

export default class ShowcaseFactory {
  static createFromJson(json) {
    return new Showcase(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(ShowcaseFactory.createFromJson(item));
    });

    return jsonData;
  }
}
