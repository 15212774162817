import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue";
// import MemberManagementCreate from "@/views/MemberManagement/member-form.vue";
import Add from "@/views/MemberManagement/add.vue";
import Edit from "@/views/MemberManagement/edit.vue";
import ServiceManagement from "@/views/ServiceManagement/service-list.vue";
import ServiceManagementCreate from "@/views/ServiceManagement/service-form.vue";
import ShowcaseManagement from "@/views/ShowcaseManagement/showcase-list.vue";
import ShowcaseManagementCreate from "@/views/ShowcaseManagement/showcase-form.vue";
import ProjectManagement from "@/views/ProjectManagement/project-list.vue";
import ProjectManagementCreate from "@/views/ProjectManagement/project-form.vue";

import ProjectProcess from "@/views/ProjectManagement/projectprocess-list.vue";

import ProjectProcessCreate from "@/views/ProjectManagement/projectprocess-form.vue";
import AboutUs from "@/views/CMS/aboutus.vue";
import ContactUs from "@/views/CMS/contactus.vue";
import PrivacyPolicy from "@/views/CMS/privacy-policy.vue";
import TermsAndConditions from "@/views/CMS/terms-condition.vue";
import ProfileSetting from "@/views/ProfileSettings/profile.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    component: MainLayout,
    children: [
      {
        path: "/member-management",
        name: "MemberManagement",
        component: MemberManagement,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member management",
        },
      },
      // {
      //   path: "/member-management/create",
      //   name: "MemberManagementCreate",
      //   component: MemberManagementCreate,
      //   meta: {
      //     requiresAuth: true,
      //     module: "MemberManagement",
      //     title: "Member Management > Add",
      //   },
      // },
      // {
      //   path: "/member-management/edit/:id",
      //   name: "MemberManagementEdit",
      //   component: MemberManagementCreate,
      //   meta: {
      //     requiresAuth: true,
      //     module: "MemberManagement",
      //     title: "Member Management > Edit",
      //   },
      // },
      {
        path: "/member-management/add",
        name: "Add",
        component: Add,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Add",
        },
      },
      {
        path: "/member-management/edit/:id",
        name: "Edit",
        component: Edit,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Edit",
        },
      },
      {
        path: "/service-management",
        name: "ServiceManagement",
        component: ServiceManagement,
        meta: {
          requiresAuth: true,
          module: "ServiceManagement",
          title: "Service management",
        },
      },
      {
        path: "/service-management/create",
        name: "ServiceManagementCreate",
        component: ServiceManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ServiceManagement",
          title: "Service Management > Add",
        },
      },
      {
        path: "/service-management/edit/:id",
        name: "ServiceManagementEdit",
        component: ServiceManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ServiceManagement",
          title: "Service Management > Edit",
        },
      },
      {
        path: "/showcase-management",
        name: "ShowcaseManagement",
        component: ShowcaseManagement,
        meta: {
          requiresAuth: true,
          module: "ShowcaseManagement",
          title: "Showcase management",
        },
      },
      {
        path: "/showcase-management/create",
        name: "ShowcaseManagementCreate",
        component: ShowcaseManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ShowcaseManagement",
          title: "Showcase Management > Add",
        },
      },
      {
        path: "/showcase-management/edit/:id",
        name: "ShowcaseManagementEdit",
        component: ShowcaseManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ShowcaseManagement",
          title: "Showcase Management > Edit",
        },
      },
      {
        path: "/project-management",
        name: "ProjectManagement",
        component: ProjectManagement,
        meta: {
          requiresAuth: true,
          module: "ProjectManagement",
          title: "Project management",
        },
      },
      {
        path: "/project-management/create",
        name: "ProjectManagementCreate",
        component: ProjectManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ProjectManagement",
          title: "Project Management > Add",
        },
      },
      {
        path: "/project-management/edit/:id",
        name: "ProjectManagementEdit",
        component: ProjectManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ProjectManagement",
          title: "Project Management > Edit",
        },
      },
      {
        path: "/project-process/:id",
        name: "ProjectProcess",
        component: ProjectProcess,
        meta: {
          requiresAuth: true,
          module: "ProjectProcess",
          title: "Project process",
        },
      },
      {
        path: "/project-process/create/:projectid",
        name: "ProjectProcessCreate",
        component: ProjectProcessCreate,
        meta: {
          requiresAuth: true,
          module: "ProjectProcess",
          title: "Project process",
        },
      },
      {
        path: "/project-process/edit/:projectid/:id",
        name: "ProjectProcessEdit",
        component: ProjectProcessCreate,
        meta: {
          requiresAuth: true,
          module: "ProjectProcess",
          title: "Project process > Edit",
        },
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
          requiresAuth: true,
          module: "AboutUs",
          title: "About Us",
        },
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: {
          requiresAuth: true,
          module: "ContactUs",
          title: "Contact Us",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: true,
          module: "PrivacyPolicy",
          title: "Privacy Policy",
        },
      },
      {
        path: "/terms-condition",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms And Conditions",
        },
      },
      {
        path: "/profile-setting",
        name: "ProfileSetting",
        component: ProfileSetting,
        meta: {
          requiresAuth: true,
          module: "ProfileSetting",
          title: "Profile Setting",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
router.beforeEach((to, from, next) => {
  const title = "DL Interiors Limited";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setUserTimeout");
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", "MemberManagement");
        next("/member-management");
        return;
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});
export default router;
