<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <!-- <span class="main-title mt-2 mb-2">User Management</span> -->
    <PrimeCard>
      <template #content>
        <DataTable
          :value="services"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="userid"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <div class="flex">
              <PrimeButton
                label="Add"
                class="add-button mr-4"
                @click="gotoCreateService"
              ></PrimeButton>
            </div>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column field="serviceid" header="#" sortable></Column>
          <Column field="title" header="Service Title" sortable></Column>
          <Column field="content" header="Service Content" sortable></Column>

          <Column field="action" header="Action">
            <template #body="data">
              <div class="flex" style="gap: 8px">
                <PrimeButton
                  label="Edit"
                  class="edit-button"
                  @click="gotoUserService(data.data.id)"
                />
                <PrimeButton
                  label="Delete"
                  class="delete-button ml-2"
                  @click="showDeletePopup(data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <ConfirmPopup
            :show-popup="showPopup"
            :header="header"
            :message="message"
            @confirmYes="deleteService"
            @confirmNo="showPopup = false"
          ></ConfirmPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import ServiceFactory from "@/factories/service";
export default {
  data() {
    return {
      visibleDDMenu: false,
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      userIdForDelete: "",
      showPopup: false,
      services: [],
    };
  },
  mounted() {
    this.getServiceList();
  },
  methods: {
    gotoCreateService() {
      this.$router.push({ name: "ServiceManagementCreate" });
    },
    gotoUserService(userId) {
      this.$router.push({
        name: "ServiceManagementEdit",
        params: { id: userId },
      });
    },
    showDeletePopup(userId) {
      this.header = "Delete Service";
      this.message =
        "Are you sure you want to delete the service? <br/> Once you confirm this action, it will be deleted <br/> immediately. You can't undo this action.";
      this.userIdForDelete = userId;
      this.showPopup = true;
    },
    getServiceList() {
      this.services = [];
      // let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/service/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        // console.log(response);
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.services = ServiceFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    deleteService() {
      APIService.delete(`/service/delete/${this.userIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getServiceList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getServiceList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "phone") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getServiceList();
    },
    // onSearch() {
    //   this.search = this.search.trimStart().trimEnd();
    //   this.currentPage = 0;
    //   this.getServiceList();
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
</style>
