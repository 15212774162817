<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.userid"
                sectionClass="field"
                id="userid"
                label="User ID"
                class="disable-text-field"
                placeholder="User Id"
                disabled
                v-if="isEditMode"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.username"
                :validation="v$.vmodel.username"
                sectionClass="field"
                id="username"
                label="User Name"
                placeholder="User Name"
                maxlength="50"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.email"
                :validation="v$.vmodel.email"
                sectionClass="field"
                id="email"
                label="Email"
                placeholder="Email"
                maxlength="50"
              />
            </div>
          </div>
          
          <div class="grid">
            <div class="col-4">
              <InputTextRequire
                v-model="vmodel.password"
                :validation="v$.vmodel.password"
                sectionClass="field"
                class="pass"
                type="password"
                id="password"
                placeholder="Password"
                label="Password"
                maxlength="20"
                minlength="8"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-4" style="margin-top: 15px;">
              <InputTextRequire
                v-model="vmodel.confirmpassword"
                :validation="v$.vmodel.confirmpassword"
                type="password"
                sectionClass="field"
                class="pass"
                id="confirmpassword"
                placeholder="Confirm Password"
                label="Confirm Password"
                maxlength="20"
                minlength="8"
              />
            </div>
          </div>

          <div class="mt-4">
            <PrimeButton
              label="Add"
              class="order-form-button"
              @click="addUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "@vuelidate/validators";
import getPassword from "@/libs/getPassword";
import { isValidPassword } from "@/services/validator.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      vmodel: {
        username: "",
        email: "",
        // phone: "",
        password: "",
        confirmpassword: "",
      },
    };
  },

  validations() {
    return {
      vmodel: {
        username: { required, max: maxLength(50) },
        // phone: { required, numeric, min: minLength(8), max: maxLength(8) },
        email: { required, email, max: maxLength(50) },
        password: {
          required,
          isValidPassword,
          min: minLength(8),
          max: maxLength(20),
        },
        confirmpassword: {
          required,
          min: minLength(8),
          max: maxLength(20),
          sameAs: sameAs(this.vmodel.password),
        },
      },
    };
  },
  methods: {
    async addUser() {
      console.log(1);
      this.v$.vmodel.$touch();
      if (this.v$.vmodel.$invalid) {
        return;
      }

      let password = getPassword.generatePassword(this.vmodel.password);
      let encodedPassword = encodeURIComponent(password);
      let payload = {
        username: this.vmodel.username,
        email: this.vmodel.email,
        password: encodedPassword,
      };

      APIService.post("/membermanagement/add", payload)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
.p-disabled,
.p-component:disabled {
  opacity: 0 !important;
  color: #1d2129 !important;
}
.p-error {
    display: block;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 40px !important;
}
</style>
