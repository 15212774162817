<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="test"><div class="test-basic">Basic Information</div></div>
        <div class="col-12">
          <div class="grid">
            <div class="col-4 flex flex-column">
              <InputTextRequire
                v-model="vmodel.title"
                :validation="v$.vmodel.title"
                sectionClass="field"
                id="title"
                label="Project Title"
                placeholder="Title"
                :required="true"
                maxlength="50"
              />
              <DropDownRequire
                v-model="vmodel.memberid"
                :validation="v$.vmodel.memberid"
                placeholder="Please Select"
                sectionClass="field mt-1"
                id="id"
                label="Member(Owner)"
                :options="categoryList"
                optionLabel="username"
                optionValue="id"
              />
            </div>
            <div class="col-4">
              <label for="photo" class="form-label">Upload Image</label>
              <div
                class="photo-section cursor-pointer mt-2 mb-3"
                @click="openFileUpload"
                :class="{
                  invalid: v$.vmodel.images.$error,
                }"
              >
                <span class="upload-image-icon"></span>
                <div class="upload-text">
                  Drag and Drop a files here or click
                </div>
              </div>
              <!-- <div class="mt-2 mb-3 upload-img-box" v-if="images !== ''">
                <img
                  :src="vmodel.images"
                  alt=""
                  class="upload-img"
                  height="80"
                  width="80"
                />
              </div> -->
              <FileUpload
                ref="photo"
                name="photo"
                @select="handleFileUpload($event)"
                accept=".png, .jpg, .jpeg"
                v-show="false"
                :aria-describedby="`photo-help`"
              />
              <div
                v-if="
                  v$.vmodel.images.$error &&
                  v$.vmodel.images.required &&
                  v$.vmodel.images.required.$invalid
                "
                class="p-error mt-2"
              >
                {{
                  v$.vmodel.images.required.$message.replace("Value", "Image")
                }}
              </div>
              <!-- v-if="documentname !== ''" -->
              <div
                class="grid"
                v-if="vmodel.images !== []"
                style="margin-top: 24px"
              >
                <div
                  v-for="(image, index) in images"
                  :key="index"
                  class="col-12 flex align-items-center"
                >
                  <div class="catalogue-label">
                    <img :src="image.baseuri" alt="" class="img-style" />
                    <span class="set-margin">{{ image.originalname }}</span>
                    <i
                      class="download-icon cursor-pointer"
                      @click="downloadfile(image.imagename)"
                    ></i>
                  </div>
                  <i
                    class="remove-icon cursor-pointer"
                    @click="deletefile(image.imagename)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createUser"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createUser"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      images: [],
      categoryList: [],
      active: false,
      documentname: "",
      documenturl: "",
      imagename: "",
      imageid: "",
      vmodel: {
        memberid: "",
        images: [],
        title: "",
        // content: "",
        imagename: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.title = "Edit User";
      this.isEditMode = true;
      this.getProjectById();
    }
    this.getyDrop();
  },
  validations() {
    return {
      vmodel: {
        images: { required },
        memberid: { required },
        title: { required, max: maxLength(50) },
        // content: { required, max: maxLength(100) },
      },
    };
  },
  methods: {
    async deletefile(image) {
      this.vmodel.images = this.vmodel.images.filter(
        (x) => x.imagename !== image
      );
      this.images = this.images.filter((x) => x.imagename !== image);
    },
    async downloadfile(imgs) {
      let image = new Image();
      let img = this.images.filter((x) => x.imagename !== imgs);
      image.src = img;
      var newTab = window.open();
      newTab.document.body.innerHTML = image.outerHTML;
    },

    openFileUpload() {
      this.$refs.photo.choose();
    },
    getyDrop() {
      APIService.get("/membermanagement/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryList = response.data.data;
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async createUser() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          images: this.images,
          title: this.vmodel.title,
          memberid: this.vmodel.memberid,
          imagename: this.vmodel.imagename,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/project", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ProjectManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/project", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ProjectManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getProjectById() {
      this.image = "";
      APIService.getById("/project", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.images = response.data.data.images;
            this.vmodel.images = response.data.data.images;
            this.vmodel.title = response.data.data.title;
            this.vmodel.memberid = response.data.data.memberid;
            // this.vmodel.username = response.data.data.username;
            this.vmodel.imagename = response.data.data.imagename;
            this.vmodel.originalname = response.data.data.originalname;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ProjectManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    handleFileUpload(fileObj) {
      // var file = this.$refs.photo.files;
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        for (let file of fileObj.files) {
          formData.append("file", file);
        }
        APIService.post("/project/project", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              this.vmodel.images = [];
              this.images = [];
              for (let file of response.data.data) {
                // if (
                //   !this.$route.params.id ||
                //   this.$route.params.id == undefined
                // ) {
                  const uploadImage = {
                    imagename: file.imagename,
                    originalname: file.originalname,
                  };
                  this.vmodel.images.push(uploadImage);
                // }
                const uploadedImg = {
                  baseuri: file.baseuri,
                  imagename: file.imagename,
                  originalname: file.originalname,
                };
                this.images.push(uploadedImg);
              }
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
      this.$refs.photo.clear();
    },
  },
};
</script>
<style lang="scss" scoped>
.test {
  width: 160px;
  height: 34px;
  padding: 5px, 16px, 5px, 16px;
  border-radius: 100px;
  gap: 10px;
  background: #f2f3f5;
}
.test-basic {
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 4px !important;
  color: #0180c3;
}
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.areaheight) {
  .p-inputtext {
    width: inherit !important;
    min-height: 58px !important;
  }
}
.img-style {
  height: 22.5px;
  width: 40px;
}
.photo-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 16px;
  gap: 24px;
  width: 100% !important;
  height: 160px;
  background: #f2f3f5;
  border: 1px dashed #e5e6eb;
  border-radius: 2px;
}
.catalogue-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  isolation: isolate;
  width: 100%;
  height: 36px;
  background: #f7f8fa;
  border-radius: 2px;
}
.set-margin {
  margin-left: 13px;
  width: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.remove-icon {
  margin-right: -20px;
}
.upload-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;
  width: 153px;
  height: 32px;
  background: #901a1c;
  border-radius: 2px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.upload-img {
  border-radius: 1px;
  align-self: center;
  align-content: end;
  text-align: center;
  margin: inherit;
  margin-left: 134px;
}
.upload-text {
  font-family: NunitoSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center !important;
  align-content: center;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 93vh !important;
}
</style>
